import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import { Flex, Text, Grid, Label } from "../utils/system";
import Header from "../components/Header";

const colors = ["orange", "pink", "blue"];

const Products = ({ data }) => {
  const { title, caption, list } = data.sanityProducts;
  return (
    <Layout>
      <Header title={title} subtitle={caption} />
      <Flex>
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          gridGap={5}
          width="85%"
          mb={6}
        >
          {list &&
            list.map((item, key) => (
              <Flex
                bg={colors[key % 3]}
                height={320}
                borderRadius={10}
                color="white"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                p={4}
              >
                <Label fontSize={5} mt={0} mb={2}>
                  {item.title}
                </Label>

                <Text>{item.description}</Text>
              </Flex>
            ))}
        </Grid>
      </Flex>
    </Layout>
  );
};

export default Products;

export const pageQuery = graphql`
  query Products {
    sanityProducts {
      title
      caption
      list {
        title
        description
      }
    }
  }
`;
